import React, { useCallback, useEffect, useState } from "react";
import Header from "../../Components/Header/Header";
import Filter from "../../Components/Filter/Filter";
import CarCard from "../../Components/CarCard/CarCard";
import "./HomeScreen.css";
import { getAllCars } from "../../services/apis";
import ErrorMessage from "../../Components/ErrorMessage/ErrorMessage";
import Loading from "../../Components/Loading/Loading";
import FilterComponent from "../../Components/FilterComponent/FilterComponent";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { BACKEND_URL } from "../../config/url";
import useMobileView from "../../hooks";
import MobileFilterComponent from "../../Components/MobileFilterComponent/MobileFilterComponent";
import NotAvailable from "../../Components/NotAvailable/NotAvailable";
import NotFound from "../../Components/NotFound/NotFound";

function HomeScreen() {
  const [loading, setLoading] = useState(false);
  const [cars, setCars] = useState([]);
  const [error, setError] = useState(null);

  const location = useLocation();
  const currentPath = location.pathname;

  const navigate = useNavigate()

  // const [cars, setCars] = useState([]);
  // const [loading, setLoading] = useState(false);
  const isMobile = useMobileView();
  const [brands, setBrands] = useState([]);
  const [years, setYears] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const itemsPerPage = 6;

  const [filters, setFilters] = useState({
    car_name: null,
    brand: null,
    model: null,
    year: null,
    kilometer: null,
    owner: null,
    shop_name: null,
    dealer: null,
    loan_available: null,
    varient: null,
    claim: null,
    sold: null,
    page: 1,
    limit: itemsPerPage,
    search: "",
  });

  useEffect(() => {
    const fetchCarsBrand = async () => {
      setLoading(true);
      try {
        const res = await axios.get(`${BACKEND_URL}/api/v1/admin/get-cars`, {
          params: null,
        });
        if (res && res.data) {
          const newCars = res?.data?.data;
          if (newCars) {
            setBrands([...new Set(res.data.data.map((car) => car.brand))]);
            setYears([...new Set(res.data.data.map((car) => car.year))]);
          }
        }
      } catch (error) {
        console.error("Error fetching cars:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchCarsBrand();
  }, []);

  useEffect(() => {
    const fetchCars = async () => {
      setLoading(true);
      try {
        const res = await axios.get(`${BACKEND_URL}/api/v1/admin/get-cars`, {
          params: filters,
        });
        if (res && res.data) {
          const newCars = res?.data?.data;
          if (newCars) {
            setCars((prevCars) => [...prevCars, ...newCars]);
            const totalItems = res.data.totalCars;
            setTotalPages(Math.ceil(totalItems / itemsPerPage));
            setHasMore(newCars.length > 0 && cars.length < totalItems);
            // setBrands([...new Set(res.data.data.map((car) => car.brand))]);
            // setYears([...new Set(res.data.data.map((car) => car.year))]);
          }
        }
      } catch (error) {
        console.error("Error fetching cars:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchCars();
  }, [filters]);

  const handleFilterChange = (updatedFilters) => {
    setCars([]);
    setFilters((prevFilters) => ({
      ...prevFilters,
      ...updatedFilters,
      page: 1,
    }));
  };

  const handleSearchChange = (e) => {
    console.log("search avlue : ", e.target.value);
    setSearchQuery(e.target.value);
  };

  const handleSearchSubmit = () => {
    console.log("submit called-------");
    setCars([]);
    setFilters((prevFilters) => ({
      ...prevFilters,
      search: searchQuery,
      page: 1,
    }));
  };

  const loadMoreCars = () => {
    if (hasMore) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        page: prevFilters.page + 1,
      }));
    }
  };

  const handleScroll = useCallback(() => {
    if (
      window.innerHeight + window.scrollY >= document.body.offsetHeight - 500 &&
      !loading &&
      hasMore
    ) {
      loadMoreCars();
    }
  }, [loading, hasMore]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  useEffect(() => {
    const fetchCars = async () => {
      setLoading(true);
      let data = await getAllCars(null);
      setLoading(false);
      if (data && data.error) {
        setError(data.message);
      } else if (data) {
        setCars(data);
      }
    };
    fetchCars();
  }, []);

  return (
    <div>
      <div className="container-fluid p-4">
        <div className="row mt-1">
          {loading ? (
            <>
              <Loading />
            </>
          ) : error ? (
            <ErrorMessage message={error} />
          ) : (
            <>
              <div className="col-md-3">
                {/* <Filter /> */}

                {isMobile ? (
                  <>
                    <MobileFilterComponent
                      brands={brands}
                      years={years}
                      onFilterChange={handleFilterChange}
                    />
                  </>
                ) : (
                  <>
                    <FilterComponent
                      brands={brands}
                      years={years}
                      onFilterChange={handleFilterChange}
                    />
                  </>
                )}
              </div>
              <div className="col-md-9">
                <div className="search-sort-bar">
                  <select className="sort-dropdown">
                    <option value="latest">Latest</option>
                    <option value="oldest">Oldest</option>
                  </select>
                </div>
                <div className="car-list">
                  {cars?.length == 0 && !loading ? (
                    <div
                      className="w-100"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <NotFound />
                    </div>
                  ) : (
                    cars.map((car) => <CarCard key={car._id} car={car} />)
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default HomeScreen;
