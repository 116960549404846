import axios from "axios";
import { BACKEND_URL } from "../config/url";

export const getAllCars = async (query) => {
  try {
    let data = await axios.get(`${BACKEND_URL}/api/v1/admin/get-cars`);
    if (data && data.data) {
      return data.data?.data;
    } else {
      return [];
    }
  } catch (error) {
    if (error.response) {
      return {
        error: true,
        message: "Server error occurred. Please try again later.",
      };
    } else if (error.request) {
      return {
        error: true,
        message: "Internal server error. Please check your connection.",
      };
    } else {
      return { error: true, message: "An unexpected error occurred." };
    }
  }
};

export const getCarById = async (id) => {
  try {
    let data = await axios.get(`${BACKEND_URL}/api/v1/admin/car/${id}`);
    if (data && data.data) {
      return data.data?.data;
    } else {
      return [];
    }
  } catch (error) {
    if (error.response) {
      return {
        error: true,
        message: "Server error occurred. Please try again later.",
      };
    } else if (error.request) {
      return {
        error: true,
        message: "Internal server error. Please check your connection.",
      };
    } else {
      return { error: true, message: "An unexpected error occurred." };
    }
  }
};

export const sendEnquiry = async (enquiry) => {
  try {
    const res = await axios.post(`${BACKEND_URL}/enquiry`, enquiry);
    if (res.status === 200) {
      return true;
    }
  } catch (error) {
    console.log("Error while sending enquiry : ", error);
    return error;
  }
};

export const getSavedCars = async (query) => {
  try {
    console.log("query : ", query);
    let data = await axios.post(
      `${BACKEND_URL}/api/v1/customer/favourites`,
      query
    );
    if (data && data.data) {
      return data.data?.data;
    } else {
      return [];
    }
  } catch (error) {
    if (error.response) {
      return {
        error: true,
        message: "Server error occurred. Please try again later.",
      };
    } else if (error.request) {
      return {
        error: true,
        message: "Internal server error. Please check your connection.",
      };
    } else {
      return { error: true, message: "An unexpected error occurred." };
    }
  }
};

export const sellMyCar = async (data) => {
  try {
    console.log("DATA--------", data);
    let data = await axios.post(
      `${BACKEND_URL}/api/v1/customer/sell-my-car`,
      data
    );
    if (data.status == 200) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log("Error while sending sell my car api : ", error);
  }
};
