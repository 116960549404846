import React, { useEffect, useState } from "react";
import "./FavouriteScreen.css";
import { getSavedCars } from "../../services/apis";

function FavouriteScreen() {
  const [savedCars, setSavedCars] = useState([]);

  const removeFromFavorites = (id) => {
    const updatedCars = savedCars.filter((car) => car._id !== id);
    setSavedCars(updatedCars);
    localStorage.setItem(
      "fav-cars",
      JSON.stringify(updatedCars.map((car) => car._id))
    );
  };

  useEffect(() => {
    const fetchSavedCars = async () => {
      let favCars = JSON.parse(localStorage.getItem("fav-cars")) || [];
      let res = await getSavedCars({ favIds: favCars });
      if (res) {
        setSavedCars(res);
      }
    };
    fetchSavedCars();
  }, []);

  return (
    <div className="favourite-screen p-5 mt-5">
      <h2 className="title mt-5">Your Favourite Cars</h2>
      {savedCars.length > 0 ? (
        <div className="car-list">
          <table className="car-table">
            <thead>
              <tr>
                <th>Image</th>
                <th>Car Name</th>
                <th>Details</th>
                <th>Price</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {savedCars.map((car) => (
                <tr key={car._id}>
                  <td>
                    <img
                      src={car.image}
                      alt={car.car_name}
                      className="car-image"
                    />
                  </td>
                  <td>{car.car_name}</td>
                  <td>
                    <p>Year: {car.year}</p>
                    <p>Fuel: {car.fuelType}</p>
                    <p>Transmission: {car.transmission_type}</p>
                    <p>Engine: {car.engine}</p>
                  </td>
                  <td>₹{car.price.toLocaleString()}</td>
                  <td>{car.sold ? "Sold" : "Available"}</td>
                  <td>
                    <button
                      className="remove-button"
                      onClick={() => removeFromFavorites(car._id)}
                    >
                      Remove from Favourites
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p className="no-cars">No saved cars yet.</p>
      )}
    </div>
  );
}

export default FavouriteScreen;
