import React, { useState, useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import "./Header.css";
import axios from "axios";
import { BACKEND_URL } from "../../config/url";

function Header() {
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [isSearchOpen, setSearchOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [showResults, setShowResults] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  const toggleSearch = () => {
    setSearchOpen(!isSearchOpen);
  };

  const sellCar = () => {
    window.location.href = "/sell-car";
  };

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleSearch = async (e) => {
    const query = e.target.value;
    setSearchTerm(query);
    if (query.length > 2) {
      const res = await axios.get(
        `${BACKEND_URL}/api/v1/admin/get-cars?search=${query}`
      );
      setSearchResults(res.data.data);
      setShowResults(true);
    } else {
      setShowResults(false);
    }
  };

  return (
    <header className={`header p-4 ${isScrolled ? "scrolled" : ""}`}>
      <div className="logo">
        <h4 onClick={() => (window.location.href = "/")}>
          Wheelz<span>Loop</span>
        </h4>
      </div>

      <div
        className={`search-box ${isSearchOpen ? "show-search" : "hide-search"}`}
      >
        <input
          type="text"
          className="search-input"
          placeholder="Search cars, services..."
          value={searchTerm}
          onChange={handleSearch}
        />
        <SearchIcon className="search-icon" style={{ fontSize: "25px" }} />
        {showResults && (
          <div className="search-results">
            {searchResults.length > 0 ? (
              searchResults.map((car) => (
                <div
                  key={car._id}
                  className="search-result-item"
                  onClick={() =>
                    (window.location.href = `/details/${car?._id}`)
                  }
                >
                  <img
                    src={car.image}
                    alt={car.car_name}
                    className="search-result-image"
                  />
                  <div className="search-result-info">
                    <h5>{car.car_name}</h5>
                    <p>
                      {car.brand} - ₹{car.price.toLocaleString()}
                    </p>
                  </div>
                </div>
              ))
            ) : (
              <div className="no-results">No results found</div>
            )}
          </div>
        )}
      </div>

      <div className="mobile-search-icon" onClick={toggleSearch}>
        {isSearchOpen ? (
          <CloseIcon />
        ) : (
          <SearchIcon style={{ fontSize: "30px" }} />
        )}
      </div>

      <nav className={`nav ${isSidebarOpen ? "open" : ""}`}>
        <div className="close-sidebar" onClick={toggleSidebar}>
          <CloseIcon style={{ fontSize: "30px", color: "#fff" }} />
        </div>
        <ul className="nav-list">
          <li className="nav-item">
            <a href="/">Home</a>
          </li>
          <li className="nav-item">
            <a href="/about-us">About Us</a>
          </li>
          <li className="nav-item">
            Cars
            <ArrowDropDownIcon />
            <ul className="dropdown">
              <li className="dropdown-item">
                <a href="/cars">Used Cars</a>
              </li>
              <li className="dropdown-item">
                <a href="/sell-car">Sell Your Car</a>
              </li>
            </ul>
          </li>
          <li className="nav-item">
            <a href="/favourites">Favourites</a>
          </li>
          <li className="nav-item">
            <button className="sell-car-btn" onClick={sellCar}>
              Sell a Car
            </button>
          </li>
        </ul>
      </nav>

      <div className="hamburger" onClick={toggleSidebar}>
        {!isSidebarOpen && <span className="hamburger-icon">&#9776;</span>}
      </div>
    </header>
  );
}

export default Header;
