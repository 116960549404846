import React, { useState } from "react";
import "./SellCarScreen.css";
import { sellMyCar } from "../../services/apis";
import Swal from "sweetalert2";
import axios from "axios";
import { BACKEND_URL } from "../../config/url";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function SellCarScreen() {
  const [carDetails, setCarDetails] = useState({
    name: "",
    phone: "",
    location: "",
    car_name: "",
    description: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCarDetails({ ...carDetails, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    Swal.fire({
      title: "Please check the details, Do you want to continue ?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Send",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let data = await axios
          .post(`${BACKEND_URL}/api/v1/customer/sell-my-car`, carDetails)
          .then(() => {
            toast.success("Your details send!, we will contact you soon");
          });
        setCarDetails({
          name: "",
          phone: "",
          location: "",
          car_name: "",
          description: "",
        });
        window.location.reload();
      }
    });
  };

  return (
    <div className="sell-car-screen">
      <div className="process-header">
        <h1>How Selling Works with WheelzLoop</h1>
        <p>We make selling your car fast, easy, and hassle-free.</p>
      </div>

      <div className="process-steps">
        <div className="step">
          <h2>Step 1: Enter Your Car Details</h2>
          <p>
            Provide basic information about your car such as the model, year,
            price, and description. This helps us understand your car better.
          </p>
        </div>
        <div className="step">
          <h2>Step 2: We Connect with You</h2>
          <p>
            Once you submit your car details, our team will review the
            information and contact you for more details or to set up an
            inspection if needed.
          </p>
        </div>
        <div className="step">
          <h2>Step 3: Find Potential Buyers</h2>
          <p>
            We list your car on our marketplace, reaching thousands of potential
            buyers. You can sit back and let us handle the process.
          </p>
        </div>
        <div className="step">
          <h2>Step 4: Finalize the Deal</h2>
          <p>
            When we find a buyer, we will assist in finalizing the sale,
            ensuring a smooth and secure transaction.
          </p>
        </div>
      </div>

      <div className="sell-car-header">
        <h1>Ready to Sell Your Car?</h1>
        <p>Fill out the form below, and we'll get started on the process!</p>
      </div>

      <form className="sell-car-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="name">Name</label>
          <input
            type="text"
            id="name"
            name="name"
            placeholder="Enter your name"
            value={carDetails.name}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="model">Contact Number</label>
          <input
            type="text"
            id="model"
            name="phone"
            placeholder="Enter Phone number"
            value={carDetails.phone}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="year">Location</label>
          <input
            type="text"
            id="year"
            name="location"
            placeholder="Enter your Location"
            value={carDetails.location}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="price">Car name</label>
          <input
            type="text"
            id="price"
            name="car_name"
            placeholder="Alto, swift ...."
            value={carDetails.car_name}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="description">Car Description</label>
          <textarea
            id="description"
            name="description"
            placeholder="Enter car description"
            value={carDetails.description}
            onChange={handleInputChange}
          ></textarea>
        </div>
        <button type="submit" className="submit-btn">
          Submit Car Details
        </button>
      </form>
      <ToastContainer />
    </div>
  );
}

export default SellCarScreen;
